import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./router";
import './assets/css/reset.scss';
import './assets/css/custom.scss';
import { initGlobal } from 'rv-gsm';
import defaultGlobal from './global';

initGlobal(defaultGlobal);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<AppRouter/>, rootElement);
} else {
  ReactDOM.createRoot(rootElement).render(<AppRouter/>);
}
