import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as R from 'ramda';

const Preview = React.lazy(() => import('./view/preview/preview').then(R.identity));
export default class AppRouter extends React.Component {
  render() {
    return (<Router>
      <Switch>
        <React.Fragment>
          <React.Suspense>
            <Route exact path="/" component={Preview} />
          </React.Suspense>
        </React.Fragment>
      </Switch>
    </Router>);
  }
}
